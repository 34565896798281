import React, { useEffect } from "react"

import type { GatsbyImageProps } from "gatsby-plugin-image"

import Container from "@ecom/ui/components/Container"
import Button from "@material-ui/core/Button"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import ShubaImg from "./Img/ShubaImg"

import * as styles from "./banner.module.scss"

type BannerProps = {
  buttonText?: string
  title?: string
  description?: string
  orderNum?: string
  Img?: React.FC<Omit<GatsbyImageProps, "image">>
  bottomText?: React.ReactNode
}

const defaultTitle = `
Открываем 
  <br/>
  Шубохранилище!
`

const defaultDescription = `
Премиальный мех — для всех!
`

const defaultBottomText = (
  <>
    <p>
      Выиграй шубу из эко-меха от карты «Халва» <br /> и телеканалов «Пятница!» и «Суббота!»
    </p>
    <span>
      Срок Акции <br /> с 22.11.2023 по 26.12.2023
    </span>
  </>
)

const handleClick = () => {
  const twoChoice = document.getElementById("TwoChoice")
  if (twoChoice) {
    twoChoice.scrollIntoView({ behavior: "smooth", block: "center" })
  }
  pushToDataLayer({
    event: "buttonClick",
    name: "Хочу шубу!",
    placement: "banner_button_halva",
  })
}

export default function BannerWithDescription({
  buttonText = "Хочу шубу!",
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
  Img = ShubaImg,
  bottomText = defaultBottomText,
}: BannerProps) {
  useEffect(() => {
    document.body.style.display = "block"
  }, [])

  return (
    <>
      <section className={styles.section} data-exclude={orderNum}>
        <Container className={styles.container}>
          <div className={styles.secondContainer}>
            <Img alt="bg image" className={styles.img} />
            <div className={styles.text}>
              <h1 dangerouslySetInnerHTML={{ __html: title }} />
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <Button onClick={handleClick} className={styles.btn}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </section>
      <div className={styles.btnBlock}>
        <Container className={styles.textBlockContainer}>
          <div className={styles.wrapper}>
            <div className={styles.textBlock}>{bottomText}</div>
            <Button onClick={handleClick} className={styles.mobBtn}>
              {buttonText}
            </Button>
          </div>
        </Container>
      </div>
    </>
  )
}
