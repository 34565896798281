// extracted by mini-css-extract-plugin
export var bannerShow = "banner-module--bannerShow--rCzwF";
export var black = "banner-module--black--4dqLr";
export var btn = "banner-module--btn--CxLQu";
export var btnBlock = "banner-module--btnBlock--F0mT8";
export var container = "banner-module--container--iVBGd";
export var darkColor = "banner-module--darkColor--PpWSb";
export var fixBottom = "banner-module--fixBottom--ZGYqW";
export var hideMobBlock = "banner-module--hideMobBlock--aGFH6";
export var img = "banner-module--img--zlLYt";
export var mobBtn = "banner-module--mobBtn--Y1E9y";
export var red = "banner-module--red--BrM29";
export var secondContainer = "banner-module--secondContainer--x4K0v";
export var section = "banner-module--section--HFIPM";
export var subtitle = "banner-module--subtitle--Vn4hn";
export var text = "banner-module--text--TP7wb";
export var textBlockContainer = "banner-module--textBlockContainer--+PPAc";
export var textItem = "banner-module--textItem--RBsBn";
export var title = "banner-module--title--f9K7h";
export var wrapper = "banner-module--wrapper--2z0oA";