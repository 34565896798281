import React from "react"

import Container from "@ecom/ui/components/Container"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"
import Link from "@material-ui/core/Link"
import Coat from "./img/coat"
import Bag from "./img/bag"

import * as styles from "./schedule.module.scss"

type SheduleProps = {
  title?: string
  description?: React.ReactNode
  orderNum?: string
}

const defaultTitle = `
Объявляем победителей каждую пятницу в эфире программы «Шубохранилище» на телеканалах «Пятница!»  и «Суббота!»
`

const handleClick = () => {
  pushToDataLayer({
    event: "buttonClick",
    name: "Итоги розыгрышей",
    placement: "schedule_button_halva",
  })
}

const defaultDescription = (
  <>
    <p>1 декабря — 4 шубы</p>
    <p> 8 декабря — 10 шуб</p>
    <p> 15 декабря — 15 шуб</p>
    <p> 22 декабря — 15 шуб</p>
    <p> 29 декабря — 20 шуб</p>
  </>
)

export function Schedule({
  title = defaultTitle,
  description = defaultDescription,
  orderNum,
}: SheduleProps) {
  return (
    <section data-exclude={orderNum} className={styles.section}>
      <Container className={styles.container}>
        <Bag alt="bg image" className={styles.bag} />
        <Coat alt="bg image" className={styles.coat} />
        <div className={styles.text}>
          <h2 className={styles.head}>{title}</h2>
          <div className={styles.subText}> {description}</div>
          <Link
            href="https://halvacard.ru/shops/actions/itogi-otkryvaem-shubohranilishe"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.btn}
            underline="none"
            onClick={handleClick}
          >
            Итоги розыгрышей
          </Link>
        </div>
      </Container>
    </section>
  )
}
